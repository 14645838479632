import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`CrossFit Open Workout 18.4`}</strong></p>
    <p>{`For time:\\
21 deadlifts, 225/155 lb.\\
21 handstand push-ups\\
15 deadlifts, 225 lb.\\
15 handstand push-ups\\
9 deadlifts, 225 lb.\\
9 handstand push-ups\\
21 deadlifts, 315/205 lb.\\
50-ft. handstand walk\\
15 deadlifts, 315 lb.\\
50-ft. handstand walk\\
9 deadlifts, 315 lb.\\
50-ft. handstand walk`}</p>
    <p>{`Time cap: 9 min.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Scaled WOD`}</em></strong></p>
    <p>{`For time:\\
21 deadlifts, 135/95 lb.\\
21 hand-release push-ups\\
15 deadlifts, 135 lb.\\
15 hand-release push-ups\\
9 deadlifts, 135 lb.\\
9 hand-release push-ups\\
21 deadlifts, 185/135 lb.\\
50-ft. bear crawl\\
15 deadlifts, 185 lb.\\
50-ft. bear crawl\\
9 deadlifts, 185 lb.\\
50-ft. bear crawl`}</p>
    <p>{`Time cap: 9 min.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will be judging today during the 9:00 & 10:00am classes as well
as from 11:00-12:00.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      